/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import PageLoader from './components/PageLoader';
import { Viewport } from './helpers/Viewport';
import Routes from './routes';
import CheckTheme from './helpers/checkTheme';
import CacheBuster from './cacheBuster';

export default function App() {
  return (
    <div id="app-view-container" className="theme-default">
      <React.Suspense
        fallback={
          <div className="w-full h-screen flex items-center">
            <PageLoader />
          </div>
        }
      >
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              // You can decide how and when you want to force reload
              refreshCacheAndReload();
            }

            return (
              <Viewport>
                <Routes />
                <CheckTheme />
              </Viewport>
            );
          }}
        </CacheBuster>
      </React.Suspense>
    </div>
  );
}
