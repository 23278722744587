import { SET_SCHEDULES } from '../action-types/schedule.types';
import { schedules as initialState } from '../initialState.json';

const scheduleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SCHEDULES:
      return [...payload];
    default:
      return state;
  }
};

export default scheduleReducer;
