import { v4 as uuid } from 'uuid';
import BaseModel from './_model';

export default class Account extends BaseModel {
  static baseAttributes() {
    return {
      balance: 0,
      public_key: uuid(),
    };
  }

  static COLLECTION() {
    return 'accounts';
  }
}
