import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Navbar from './components/Navbar';
import { useViewport } from './helpers/Viewport';
import { checkAuth } from './redux/actions/auth.actions';

const Home = React.lazy(() => import('./pages/Home'));
const Schedule = React.lazy(() => import('./pages/Schedule'));
const Join = React.lazy(() => import('./pages/Join'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const Transaction = React.lazy(() => import('./pages/Transaction'));
const NotFound = React.lazy(() => import('./pages/404page'));
const DesktopTablet = React.lazy(() =>
  import('./pages/DesktopTabletView'),
);

const Routes = () => {
  const dispatch = useDispatch();
  const layout = useSelector((state) => state.layout);
  useEffect(() => {
    dispatch(checkAuth());
  });
  const { width, mobileBrowser } = useViewport();
  const breakpoint = 850;
  return (
    <>
      {width < breakpoint && mobileBrowser ? (
        <Router>
          {layout.navbarVisible && <Navbar balance={500} />}

          <div className="p-6 w-full h-full m-0">
            <Switch>
              <Route exact path="/" render={() => <Home />} />
              <Route path="/schedule" render={() => <Schedule />} />
              <Route path="/join" render={() => <Join />} />
              <Route path="/sign-up" render={() => <SignUp />} />
              <Route
                path="/transactions"
                render={() => <Transaction />}
              />
              <Route path="*" render={() => <NotFound />} />
            </Switch>
          </div>
        </Router>
      ) : (
        <Router>
          <Route exact path="*" render={() => <DesktopTablet />} />
        </Router>
      )}
    </>
  );
};

export default Routes;
