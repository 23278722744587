import { SET_AUTH } from '../action-types/auth.types';
import firebase from '../../lib/firebase';
import { getAccount } from './account.actions';
import { clearAlert } from './alert.actions';

export const setAuth = (payload) => (dispatch) => {
  dispatch({
    type: SET_AUTH,
    payload,
  });
  dispatch(getAccount());
};

export const checkAuth = () => (dispatch) => {
  firebase.auth().onAuthStateChanged((authUser) => {
    if (authUser) {
      return dispatch(
        setAuth({
          loaded: true,
          user: authUser,
        }),
      );
    }
    return dispatch(
      setAuth({
        loaded: true,
        user: null,
      }),
    );
  });
};

export const logout = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(
          setAuth({
            loaded: true,
            user: null,
          }),
        );
        dispatch(clearAlert());
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
