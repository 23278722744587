import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Moment from 'moment';
import DropsSymbol from '../assets/icons/dropsSymbol';
import SmallLogo from '../assets/icons/smallLogo';
import BigLogo from '../assets/icons/bigLogo';
import pattern from '../assets/icons/pattern.png';
import Menu from './menu';

class Navbar extends React.Component {
  toggle = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  };

  renderBalance() {
    const { account, t } = this.props;
    if (account && account.data && account.data.balance >= 0) {
      return (
        <Link
          to="/transactions"
          className="text-color flex items-baseline"
        >
          <span className="text-color">{t('navbar.balance')}</span>
          <span className="tracking-tight flex items-baseline mx-1 text-color">
            <span className="font-medium text-lg">
              {account.data.balance}
            </span>
            <DropsSymbol color="color" />
          </span>
        </Link>
      );
    }
    return (
      <span className="text-color">
        &nbsp;{' '}
        {t('balance.placeholder')
          ? t('balance.placeholder')
          : Moment().format('MMMM Do')}
      </span>
    );
  }

  render() {
    const { layout, theme } = this.props;
    return (
      <>
        <div className="relative z-99">
          <Menu />
          <div className="bg-secondary fixed border-b border-white border-opacity-25 h-16 w-full pt-3 px-3 z-0">
            <nav className="z-99 bg-secondary flex pt-2 items-center">
              <div className="mr- ml-12">
                <Link to="/">
                  <SmallLogo />
                </Link>
              </div>
              <div className="ml-auto mr-5">
                <ul>
                  <li className="text-sm text-color">
                    {this.renderBalance()}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          {layout.navbarExpanded ? (
            <div className="relative bg-secondary inline-block w-full overflow-hidden py-4 pb-12 top-0 border-b border-white border-opacity-25">
              <Link to="/">
                <BigLogo />
              </Link>
              <span className="absolute -mt-1 right-0 mr-6 z-1">
                {this.renderBalance()}
              </span>
              {theme === 'halloween' && (
                <div
                  className="absolute top-0 left-0 w-full h-64"
                  style={{
                    background: ` linear-gradient(180deg, rgba(255, 255, 255, 0),rgba(255, 255, 255, 0), rgba(110, 88, 146, 0.8)), url(${pattern})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                  }}
                />
              )}
            </div>
          ) : (
            <div className="py-12" />
          )}
        </div>
      </>
    );
  }
}

Navbar.propTypes = {
  account: PropTypes.instanceOf(Object),
  layout: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

Navbar.defaultProps = {
  account: null,
  theme: null,
};

export const mapStateToProps = (state) => ({
  account: state.account,
  layout: state.layout,
  theme: state.theme,
});

export default connect(mapStateToProps)(withTranslation()(Navbar));
