import { SET_ACCOUNT } from '../action-types/account.types';
import Beneficiary from '../../models/beneficiary';

export const getAccount = () => (dispatch, getState) => {
  const { auth } = getState();
  if (auth.loaded && auth.user) {
    return Beneficiary.find(auth.user.uid)
      .then((beneficiary) => {
        beneficiary
          .getAccount()
          .then((account) => {
            dispatch({
              type: SET_ACCOUNT,
              payload: account,
            });
          })
          .catch(() => {
            dispatch({
              type: SET_ACCOUNT,
              payload: null,
            });
          });
      })
      .catch(() => {
        dispatch({
          type: SET_ACCOUNT,
          payload: null,
        });
      });
  }

  return dispatch({
    type: SET_ACCOUNT,
    payload: null,
  });
};

export const updateBalance = (amount) => (dispatch, getState) => {
  const { account } = getState();
  if (account && account.balance) {
    account.balance -= amount;
    dispatch({
      type: SET_ACCOUNT,
      payload: account,
    });
  }
};
