import { v4 as uuid } from 'uuid';
import BaseModel from './_model';
import Account from './account';

export default class Beneficiary extends BaseModel {
  static baseAttributes() {
    return {
      name: '',
      phone: uuid(),
    };
  }

  static COLLECTION() {
    return 'beneficiaries';
  }

  getAccount() {
    return new Promise((resolve, reject) => {
      this.data.account
        .get()
        .then((data) => {
          resolve(Account.structureObject(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
