import { SET_TRANSACTIONS } from '../action-types/transaction.types';
import { transactions as initialState } from '../initialState.json';

const transactionReducer = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case SET_TRANSACTIONS:
      return [...payload];
    default:
      return state;
  }
};

export default transactionReducer;
