import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  persistStore,
  persistReducer,
  createTransform,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { parse, stringify } from 'flatted';
import initialState from './initialState.json';
import rootReducer from './reducers/rootReducer';

export const transformCircular = createTransform(
  (inboundState) => stringify(inboundState),
  (outboundState) => parse(outboundState),
);

const persistConfig = {
  key: 'dropsy-storage',
  storage,
  whitelist: ['auth', 'account'],
  transforms: [transformCircular], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  pReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk)),
);

const persistor = persistStore(store);

export { persistor, store };
