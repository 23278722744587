import { SET_ACCOUNT } from '../action-types/account.types';
import { account as initialState } from '../initialState.json';

const accountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACCOUNT:
      return payload;
    default:
      return state;
  }
};

export default accountReducer;
