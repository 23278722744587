import { combineReducers } from 'redux';

import schedulesReducer from './schedule.reducer';
import layoutReducer from './layout.reducer';
import authReducer from './auth.reducer';
import accountReducer from './account.reducer';
import transactionReducer from './transaction.reducer';
import alertReducer from './alert.reducer';
import themeReducer from './theme.reducer';

const rootReducer = combineReducers({
  schedules: schedulesReducer,
  layout: layoutReducer,
  auth: authReducer,
  account: accountReducer,
  transactions: transactionReducer,
  alert: alertReducer,
  theme: themeReducer,
});

export default rootReducer;
